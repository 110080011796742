
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import * as React from "react";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useAuth } from "../lib/auth";
import { getUserDetails, updateUser } from "../utils/service/user";
import { getBrowserLocales } from "../utils/usersettings";
import setLanguage from "next-translate/setLanguage";
import PageWrapper from "../components/PageWrapper";

import {
  Box,
  Center,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

const authenticating = () => {
  const router = useRouter();
  const { auth } = useAuth();
  const [redirect, setRedirect] = useState<boolean>(false);
  const [locale, setLocale] = useState(null);

  const timer = setTimeout(() => {
    setRedirect(true);
  }, 2000);

  useEffect(() => {
    if (locale != null) {
      router.push("/landing");
    }
  }, [locale]);

  useEffect(() => {
    if (auth) {
      setLocale(getBrowserLocales()[0].slice(0, 2));
      setAppLanguage();
    }
  }, [auth]);

  async function setAppLanguage() {
    // Get user object from db
    await getUserDetails(auth.token, auth.uid).then(async (user) => {
      if (user.success) {
        // checking if locale is set in db and then setting locale to that language if so.
        if (user.data.locale === "en") {
          await setLanguage("en").then(() => setLocale(true));
        }
        if (user.data.locale === "nl") {
          await setLanguage("nl").then(() => setLocale(true));
        }
        if (user.data.locale === "") {
          // If locale has not yet been set for this user:
          // 1. grab from browser 2. update user object 3. continue (true).
          const browserLocale = getBrowserLocales()[0].slice(0, 2);
          if (browserLocale === "en" || browserLocale === "nl")
            await updateUser(auth.token, auth.uid, {
              locale: browserLocale,
            }).then(async () => {
              await setLanguage(browserLocale).then(() => setLocale(true));
            });
        }
      } else {
        // fallback option, sets language to English.
        await setLanguage("en").then(() => setLocale(true));
      }
    });
  }

  return (
    <PageWrapper title='Authenticating'>
      <Center bg={useColorModeValue("gray.50", "inherit")} minH='100vh'>
        <VStack>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </VStack>
      </Center>
    </PageWrapper>
  );
};

export default authenticating;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/authenticating',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  